import { useState } from "react"
import QRCode from "react-qr-code";
import './App.css';

function App() {
  const [value, setValue] = useState("")

  return (
    <div className="App">
      <div style={{ height: "auto", margin: "50px auto", maxWidth: 64, width: "100%" }}>
        <input type="text" value={value} onChange={(e) => setValue(e.target.value)}/>
        <br /> <br />
        <QRCode
          size={500}
          style={{ height: "auto", maxWidth: "unset",  width: 400 }}
          value={value}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
}

export default App;
